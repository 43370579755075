<template>
  <div class="pl-shopping-cart-wrap">
    <div class="module-title">我的购物车</div>
    <el-table
        :span-method="arraySpanMethod" :data="tableData" ref="multipleTable" border class="pl-table"
        @select-all="handleSelectionChange">
      <el-table-column type="selection" width="55">
        <template slot-scope="scope">
          <el-checkbox
              v-if="scope.row.goods.status == 1" :data-index="scope.$index" v-model="scope.row.checked"
              @change="checkboxChange"></el-checkbox>
          <span v-else>此商品已失效</span>
        </template>
      </el-table-column>
      <el-table-column label="商品名称" width="150" prop="goods_name" header-align="center" align="center">
        <template slot-scope="scope">
          <div class="pointer" @click="goShopingDetail(scope.row, scope)">{{ scope.row.goods_name }}</div>
        </template>
      </el-table-column>
      <el-table-column
          label="型号" width="150" prop="production_model" header-align="center"
          align="center"></el-table-column>
      <el-table-column label="采购人" prop="person" header-align="center" align="center">
        <template slot-scope="scope">
          <span>{{ scope.row.user.name }}</span>
        </template>
      </el-table-column>
      <el-table-column label="数量" width="120" header-align="center" align="center">
        <template slot-scope="scope">
          <el-input-number
              size="mini" v-model="scope.row.quantity" :min="1"
              @change="handleChange(scope.row)"></el-input-number>
        </template>
      </el-table-column>
      <el-table-column label="折扣单价" prop="price" header-align="center" align="center">
        <template slot-scope="scope">
          <span>{{ getDiscountPrice(scope.row) }}</span>
          <!-- <span>{{+scope.row.quantity * +scope.row.tax_price || '0.00'}}</span> -->
        </template>
      </el-table-column>
      <el-table-column label="折扣单价(含税)" prop="tax_price" header-align="center" align="center">
        <template slot-scope="scope">
          <span>{{ getDiscountTaxPrice(scope.row) }}</span>
          <!-- <span>{{+scope.row.quantity * +scope.row.tax_price || '0.00'}}</span> -->
        </template>
      </el-table-column>
      <el-table-column label="发货天数" prop="ship_days" header-align="center" align="center"></el-table-column>
      <el-table-column label="合计" prop="total_price" header-align="center" align="center">
        <template slot-scope="scope">
          <span>{{ getDiscountPriceTotal(scope.row) }}</span>
          <!-- <span>{{+scope.row.quantity * +scope.row.tax_price || '0.00'}}</span> -->
        </template>
      </el-table-column>
      <el-table-column label="操作" header-align="center" align="center">
        <template slot-scope="scope">
          <div class="pl-img-box" @click="del(scope)">
            <img :src="delIcon" alt="">
          </div>
        </template>
      </el-table-column>
    </el-table>
    <div class="pl-pagination">
      <span>总共{{ pages.total }}条记录</span>
      <el-pagination
          background layout="prev, pager, next" :page-size="pages.per_page" :total="pages.total"
          @current-change="changePage">
      </el-pagination>
    </div>
    <div class="pl-footer pl-flex">
      <div class="pl-footer-left">
        <span @click="delShoppings">删除所选商品</span>
        <span @click="clearShoppings">清空购物车</span>
        <span @click="collects">添加到收藏</span>
      </div>
      <div class="pl-btn-box">
        <el-button type="primary" style="background: #fff"  plain @click="goQuote">转为报价单</el-button>
        <el-button type="primary" @click="goOrder">转换成订单</el-button>
      </div>
    </div>
  </div>
</template>
<script>
import {mapActions} from 'vuex'
import * as math from 'mathjs';
import delIcon from '@/assets/images/user/del.png';
import {
  clearShoppings,
  collects,
  convertOrder,
  delShopping,
  delShoppings,
  getShopingList,
  quotationPrice,
  updateShopping
} from '@/api/shoping.js'

export default {
  data() {
    return {
      delIcon,
      tableData: [],
      pages: {
        per_page: 20,
        page: 1,
        total: 0
      },
      ids: [],
      collectArr: []
    }
  },
  filters: {
    getCurPrice: (goodsRow, price) => {
      console.log('goodsRow', goodsRow)
      console.log('price', price)
      if (goodsRow.isNotfound)
        return ''
      let curDiscount = ''
      let curDiscountList = goodsRow.goods.goods_discount.filter(item => {
        // console.log(item.max * 1 <= goodsRow.quantity * 1)
        if (goodsRow.quantity * 1 <= item.max * 1 && goodsRow.quantity * 1 >= item.min)
          return item
      })
      if (curDiscountList.length)
        curDiscount = curDiscountList[0].discount
      console.log('curDiscountList', curDiscountList)
      console.log('curDiscount', curDiscount)
      console.log(math.multiply(math.bignumber(price * 1), math.bignumber(curDiscount * 1)).toString())

      return (math.multiply(math.bignumber(price * 1), math.bignumber(curDiscount * 1)).toString() * 1).toFixed(2)
    },
  },
  methods: {
    ...mapActions([
      'setCarNumber'
    ]),
    getDiscountPrice(rowData){
      if (rowData.goods && rowData.goods.status == 1 && Array.isArray(rowData.goods.goods_discount)) {
        const goods_discount = rowData.goods.goods_discount;
        let discount = 1;
        for (let i = 0; i < goods_discount.length; i++) {
          if (goods_discount[i].min <= rowData.quantity && goods_discount[i].max >= rowData.quantity) {
            discount = goods_discount[i].discount
            break;
          }
        }
        //return "0.00"
        //console.log(rowData)
        return this.getBit(this.accMul(rowData.price, discount)) || "0.00"
      } else {
        return "0.00"
      }
    },
    getDiscountTaxPrice(rowData){
      if (rowData.goods && rowData.goods.status == 1 && Array.isArray(rowData.goods.goods_discount)) {
        const goods_discount = rowData.goods.goods_discount;
        let discount = 1;
        for (let i = 0; i < goods_discount.length; i++) {
          if (goods_discount[i].min <= rowData.quantity && goods_discount[i].max >= rowData.quantity) {
            discount = goods_discount[i].discount
            break;
          }
        }
        return this.getBit(this.accMul(rowData.tax_price, discount)) || "0.00"
      } else {
        return "0.00"
      }
    },
    getDiscountPriceTotal(rowData) {
      // console.log(rowData)
      if (rowData.goods && rowData.goods.status == 1 && Array.isArray(rowData.goods.goods_discount)) {
        const goods_discount = rowData.goods.goods_discount;
        let discount = 1;
        for (let i = 0; i < goods_discount.length; i++) {
          if (goods_discount[i].min <= rowData.quantity && goods_discount[i].max >= rowData.quantity) {
            discount = goods_discount[i].discount
            break;
          }
        }
        return this.getBit(this.accMul(this.accMul(rowData.quantity, rowData.tax_price), discount)) || "0.00"
      } else {
        return "0.00"
      }
    },
    accMul(arg1, arg2) {
      var m = 0;
      m += this.deal(arg1);
      m += this.deal(arg2);
      var r1 = Number(arg1.toString().replace(".", ""));
      var r2 = Number(arg2.toString().replace(".", ""));
      return (r1 * r2) / Math.pow(10, m)
    },
    deal(arg) {
      var t = 0;
      try {
        t = arg.toString().split(".")[1]?.length || 0
      } catch (e) {
        console.log(e)
      }
      return t;
    },
    collects() {
      if (!this.collectArr.length) {
        this.$message.error('请选择商品')
        return
      }
      collects({goods: this.collectArr}).then(() => {
        this.$message.success('收藏成功')
      })
    },
    del({row}) {
      delShopping(row.id).then(() => {
        this.$message.success('删除成功')
        this.pages.page = 1
        this.getShopingList()
      })
    },
    delShoppings() {
      if (!this.ids.length) {

        this.$message.error('请选择商品')
        return
      }
      delShoppings({ids: this.ids}).then(() => {
        this.pages.page = 1
        this.getShopingList()
        this.$message.error('删除成功')
        this.setCarNumber()
      })
    },
    clearShoppings() {
      if (!this.tableData.length) {
        this.$message.error('购物车已清空')
        return
      }
      clearShoppings().then(() => {
        this.$message.error('购物车已清空')
        this.tableData = []
        this.getShopingList()
        this.setCarNumber()
      })
    },
    goOrder() {
      if (!this.ids.length) {
        this.$message.error('请选择商品')
        return
      }
      convertOrder({shoping_cart_ids: this.ids}).then(res => {
        this.$router.push({path: '/user/shopping/order', query: res || {}})
      })
    },
    goQuote() {
      if (!this.ids.length) {
        this.$message.error('请选择商品')
        return
      }
      quotationPrice({shoping_cart_ids: this.ids}).then(() => {
        this.$message.error('转为报价单成功')
        this.pages.page = 1
        this.getShopingList()
      })
    },
    getShopingList() {
      getShopingList(this.pages).then(res => {
        let {total, data} = res
        this.pages.total = total || 0
        const promiseList = data.map(item => {
          if (item.goods && item.goods.status == 1) {
            return new Promise((resove, reject) => {
              item.checked = false

              let goods_discount = item.goods.goods_discount;

              if (goods_discount && Array.isArray(goods_discount)) {
                if (!goods_discount.some(j => j.ask_price_type == 0 && item.quantity >= j.min && j.max >= item.quantity)) {

                  item.isNotfound = true
                  item.price = '暂无价格请转报价单'
                }
              }
              if (this.isNumber(item.price) && item.price > 0) {
                item.initPrice = item.price
                item.initTaxPrice = item.tax_price
                resove(item)
              } else {
                item.isNotfound = true
                item.price = '暂无价格请转报价单'
                this.$http.post('/api/goods/selection', {
                  goods_id: item.goods_id,
                  production_model: item.production_model
                }).then((data) => {

                  //console.log(data.pro_selection2)
                  data.pro_selection2 = data.pro_selection2.filter((item) => item.var_type == 1 || item.var_type == 2 || item.param_type == 3)
                  data.pro_code = data.pro_code.filter((item) => {
                    return data.pro_selection2.some((j) => j.goods_param_id == item.id)
                  })
                  data.pro_selection2 = data.pro_selection2.filter((item) => {
                    return data.pro_code.some((j) => j.id == item.goods_param_id)
                  })
                  data.pro_selection2.sort((a, b) => a.sort - b.sort)
                  data.optional_processing = data.optional_processing.filter(j => j.var_type == 3 && (j.param_type == 2 || j.param_type == 4))


                  const money = this.priceSort(data.pro_selection2, data.optional_processing, data.pro_code, item.production_model)

                  if (money) {
                    item.initPrice = money
                    item.initTaxPrice = this.getBit(this.accMul(money, 1.13))
                    console.log("=======", item)
                    resove(item)
                  } else {
                    console.log("=======", item)
                    resove(item)
                  }
                  //price: this.priceData.price, tax_price: this.priceData.tax_price, total_price: this.priceData.total_price

                })
              }
            })
          } else {
            return new Promise((r) => {
              Object.assign(item, {goods: {status: 0, goods_discount: []}})
              r(item)
            })
          }

        })

        Promise.all(promiseList).then(data => {
          console.log('data', data)
          this.tableData = data || []
        })
      })
    },
    isNumber(num) {
      return /^[0-9]+.?[0-9]*$/.test(num)
    },
    dealTZSF(str, colName, colNameVal, rowName) {
      colNameVal=colNameVal.map(item=>isNaN(item)?item:parseFloat(item))
      if (str.split("\n").length > 1 && !str.endsWith("\n")) {
        str += "\n"
      }
      colName.map((item, index) => {
        window[item] = colNameVal[index]
      })

      const newstr = str.replaceAll("THEN", "")
          .replaceAll(".AND.", " && ")
          .replaceAll(".OR.", " || ")
          .replaceAll(".EQ.", " == ")
          .replaceAll(".GE.", " >= ")
          .replaceAll(".LE.", " <= ")
          .replaceAll(".GT.", " > ")
          .replaceAll(".LT.", " < ")
          .replaceAll(".NE.", " != ")
          .replaceAll("ELSEIF", "if")
          .replace("ENDIF", "")
          .replaceAll("IF", "if")
          .replace("ELSE", "else")
      let bianliang;
      try {
        const newarr = newstr.match(/.*\n/g); //此处 \n 或 \r
        let newarr1 = newstr;
        if (newarr) {
          newarr1 = newarr.map((str, index) => {

            bianliang = str.match(/\s*([A-Z0-9\u4e00-\u9fa5]+)\s*=\s*'?(\S+)'?\s*\n/)
            if (index == 1 && bianliang) {
              window[bianliang[1]] = null;
            }
            if (bianliang && !bianliang[2].endsWith("'")) {
              str = ` ${bianliang[1]}='${bianliang[2]}'\n`
            }
            if (str.startsWith("if") || str.startsWith("else")) {
              return str.replace(/[\n\r\s]+$/, "")
            } else {
              return str.replace(/[\n\r\s]+$/, ";")
            }
          })//.join("");
					let evalstr=""
					for(let i=0;i<newarr1.length;i++){
						if(newarr1[i].startsWith("if")){
							let d=eval(evalstr);
							if(d !== undefined){
								return d
							}else{
								evalstr=newarr1[i]
							}
						}else if(newarr1[i].startsWith("else")){
							let d=eval(newarr1[i+1]);
							return d
						}else{
							evalstr+=newarr1[i];
						}

						if( i == newarr1.length-1){
							let d=eval(evalstr);
							if(d !== undefined){
								return d
							}
						}
					}
					return undefined;
        } else {
          bianliang = newstr.match(/(\s+)?([A-Z0-9\u4e00-\u9fa5]+)\s*=\s*'?([a-zA-Z0-9,\u4e00-\u9fa5]+).*/)
          if (bianliang) {
            bianliang[1] = bianliang[2];
            window[bianliang[1]] = null;
          }
        }
        let evalResule = eval(newarr1)
        return evalResule;
      } catch (error) {
        this.$message.error(`${rowName}的特征算法解析错误`);
        return null;
      }
    },
    priceSort(txsfArr, optional_processing, pro_code, production_model) {
      const pro_selection2Arr = []//production_model.split("-");
      if (pro_code.length != 1) {
        return null;
      }
      pro_code = pro_code[0].pro_code;
      if (production_model.startsWith(`${pro_code}`)) {
        production_model = production_model.replace(`${pro_code}`, "")
      } else {
        return null;
      }
      for (let i = 0; i < txsfArr.length; i++) {
        if (txsfArr[i].var_type == 1) {
          if (txsfArr[i].param_type == 1) {
            let product_param = txsfArr[i].product_param.replace(/[\u4e00-\u9fa5]/g, "")
            let specific_param = txsfArr[i].specific_param.split(",").filter((j) => j.trim())
            let notpipei = true;
            for (let j = 0; j < specific_param.length; j++) {
              if (production_model.startsWith(`-${product_param}${specific_param[j]}-`)) {
                pro_selection2Arr.push(`${txsfArr[i].product_param}${specific_param[j]}`)
                production_model = production_model.replace(`-${product_param}${specific_param[j]}`, "")
                notpipei = false
                break;
              } else if (production_model == `-${product_param}${specific_param[j]}`) {
                pro_selection2Arr.push(`${txsfArr[i].product_param}${specific_param[j]}`)
                production_model = ""
                notpipei = false
                break;
              }
            }
            if (notpipei) {
              pro_selection2Arr.push(`${txsfArr[i].product_param}无`)
            }
          } else if (txsfArr[i].param_type == 2) {
            let product_param = txsfArr[i].product_param.replace(/[\u4e00-\u9fa5]/g, "")
            const reg1 = new RegExp(`^-${product_param}(\\d+(.\\d+)?)-`)
            const reg2 = new RegExp(`^-${product_param}(\\d+(.\\d+)?)$`)
            const match1 = production_model.match(reg1)
            const match2 = production_model.match(reg2)
            if (match1) {
              pro_selection2Arr.push(`${txsfArr[i].product_param}${match1[1]}`)
              production_model = production_model.replace(`-${product_param}${match1[1]}`, "")
            } else if (match2) {
              pro_selection2Arr.push(`${txsfArr[i].product_param}${match2[1]}`)
              production_model = ""
            } else {
              return null
            }
          }
        }
      }
      let optionalPrice = 0;
      //pro_selection2Arr.shift()
      //production_model
      if (production_model != "") {
        for (let i = 0; i < optional_processing.length; i++) {
          let op = optional_processing[i];
          if (op.var_type == 3) {
            if (op.param_type == 4) {
              op.specific_param = op.specific_param.split(",")
              op.specific_param.map((opItem) => {
                if (production_model.startsWith(`-${opItem}-`)) {
                  optionalPrice = this.accAdd(optionalPrice, 1);
                  production_model = production_model.replace(`-${opItem}`, "")
                  console.log(production_model)
                } else if (production_model == `-${opItem}`) {
                  optionalPrice = this.accAdd(optionalPrice, 1);
                  production_model = ""
                }
              })
            } else if (op.param_type == 2) {

              if (production_model.startsWith(`-${op.product_param}-`)) {
                let temp_production_model = production_model;
                let flag = true;
                production_model = production_model.replace(`-${op.product_param}`, "")

                op.specific_param = op.specific_param.split(",");
                op.specific_param.map((opItem) => {
                  const regresult = opItem.match(/^(.+)\:\d+(\.\d+)?~\d+(\.\d+)?\/\d+(\.\d+)?$/);
                  if (regresult) {
                    const reg1 = new RegExp(`^-${regresult[1]}(\\d+(.\\d+)?)-`)
                    const reg2 = new RegExp(`^-${regresult[1]}(\\d+(.\\d+)?)$`)
                    const match1 = production_model.match(reg1)
                    const match2 = production_model.match(reg2)
                    if (match1) {
                      flag = false;
                      optionalPrice = this.accAdd(optionalPrice, match1[1]);
                      production_model = production_model.replace(`-${regresult[1]}${match1[1]}`, "")
                    } else if (match2) {
                      flag = false;
                      optionalPrice = this.accAdd(optionalPrice, match2[1]);
                      production_model = ""
                    }
                  }
                })
                if (flag) {
                  production_model = temp_production_model;
                }
              }

            }
          }
          if (production_model == "") {
            break;
          }
        }
      }
      if (production_model != "") {
        console.log("============================价格解析错误=============================", production_model)
        return null
      }


      let noSort = [];
      let needSort = [];
      let noSortArr = [];
      let oneFlag = true;
      let jiage = {}
      txsfArr.map(i => {
        if (i.var_type == 1) {
          noSort.push(i)
          noSortArr.push(i)
        } else if (i.var_type == 2) {
          if (i.quote_params.length == 0) {
            noSort.push(i)
          } else {
            needSort.push(i)
          }
        } else if (i.param_type == 3 && oneFlag) {
          Object.assign(jiage, i);
          oneFlag = false;
        }
      })

      const s = this.dataSort(noSort, needSort)//递归获取排序结果
      s.push(jiage)
      //console.log(s, pro_selection2Arr, optionalPrice)
      let colName = [];
      let colNameVal = [];

      for (let i = 0; i < noSortArr.length; i++) {
        colName.push(noSortArr[i].product_param)
        if (noSortArr[i].var_type == 1) {
          //页面显示的获取页面输入的结果
          let flag = false;
          let val = NaN;
          const reg = new RegExp(`^${noSortArr[i].product_param}(\\d+(\.\\d+)?)$`);
          for (let j = 0; j < pro_selection2Arr.length; j++) {
            if (reg.test(pro_selection2Arr[j])) {
              val = pro_selection2Arr[j].match(reg)[1]
              break;
            }
          }
          colNameVal.push(val);
        }
      }
      for (let i = 0; i < s.length; i++) {
        if (s[i].var_type != 1) {//不显示的数据执行特征算法
          colName.push(s[i].product_param)

          if (s[i].tzsf) {
            let val = this.dealTZSF(s[i].tzsf, colName, colNameVal, s[i].product_param)
            if (i + 1 == s.length) {
              return this.getBit(this.accAdd(val, optionalPrice))
            } else {
              if (val) {
                colNameVal.push(val)
              } else {
                colNameVal.push(NaN)
              }
            }

          } else {
            colNameVal.push(NaN)
          }
        }
      }
      return null;
    },
    //递归获取排序结果
    dataSort(noSort, needSort) {
      for (let i = 0; i < needSort.length; i++) {
        needSort[i].quote_params = needSort[i].quote_params.filter(j => !noSort.some(k => k.product_param == j))//过滤掉noSort的字段
        if (needSort[i].quote_params.length == 0) {
          noSort.push(needSort[i])
          needSort.splice(i, 1);
          i--;
        }
      }
      //递归调用
      if (needSort.length > 0) {
        return this.dataSort(noSort, needSort)
      } else {
        return noSort
      }
    },
    accMul(arg1, arg2) {
      // console.log()
      var m = 0, s1 = arg1.toString(), s2 = arg2.toString();
      try {
        m += s1.split(".")[1].length
      } catch (e) {
      }
      try {
        m += s2.split(".")[1].length
      } catch (e) {
      }
      return Number(s1.replace(".", "")) * Number(s2.replace(".", "")) / Math.pow(10, m)
    },
    //向下取整两位小数点
		getBit(value, bit = 4) {
			let str = value.toString();
			let strIndex = str.indexOf(".");
			if (strIndex == -1) return value
			str = str.substring(0, strIndex + bit)
			let num= Number.parseFloat(str);
			return Math.round((num) * 100) / 100;
		},
    accAdd(arg1, arg2) {
      var r1, r2, m;
      try {
        r1 = arg1.toString().split(".")[1].length
      } catch (e) {
        r1 = 0
      }
      try {
        r2 = arg2.toString().split(".")[1].length
      } catch (e) {
        r2 = 0
      }
      m = Math.pow(10, Math.max(r1, r2))
      return (arg1 * m + arg2 * m) / m
    },
    arraySpanMethod({row, column, rowIndex, columnIndex}) {
      if (row.isNotfound) {
        // console.log(row, column, rowIndex, columnIndex)
        if (columnIndex === 5) {
          return [1, 4]
        } else if ([5, 6, 7, 8].indexOf(columnIndex) > -1) {
          return [0, 0]
        }
      }
    },
    changePage(v) {
      this.pages.page = v
      this.getShopingList()
    },
    handleSelectionChange(val) {
      if (this.ids.length > 0) {
        this.ids = []
        this.collectArr = []
        this.$refs.multipleTable.clearSelection()
        this.tableData.forEach(item => {
          item.checked = false
        })
      } else {
        let ids = []
        let arr = []
        val.forEach(item => {
          if (item.goods.status === 1) {
            ids.push(item.id)
            let {goods_id, production_model} = item
            arr.push({goods_id, product_model: production_model})
          } else {
            // this.$refs.multipleTable.toggleRowSelection(item, false)
          }
        })
        this.tableData.forEach(item => {
          item.checked = ids.indexOf(item.id) > -1
        })
        this.ids = [...ids]
        this.collectArr = arr
      }
    },
    checkboxChange(v) {
      let {target} = event
      let index = target.parentNode.parentNode.dataset.index
      let item = this.tableData[index]
      if (v) {
        if (!this.ids.includes(item.id)) {
          this.ids.push(item.id)
        }
        let {goods_id, production_model, id} = item
        this.collectArr.push({goods_id, product_model: production_model, id})
      } else {
        if (this.ids.includes(item.id)) {
          let ids = []
          this.ids.forEach(id => {
            if (id !== item.id) {
              ids.push(id)
            }
          })
          this.ids = ids
          let arr = []
          this.collectArr.forEach(o=> {
            if (item.id !== o.id) {
              arr.push(o)
            }
          })
          this.collectArr = arr
        }
      }
      this.$refs.multipleTable.toggleRowSelection(item, v)

    },
    goShopingDetail(info) {
      if (info.goods.status == 0) return ''
      this.$router.push({path: '/goods/detail', query: {id: info.goods_id}})
    },
    handleChange(val) {
      if (!val) return '';
      let {
        id,
        goods_id,
        production_model,
        quantity,
        price,
        tax_price,
        total_price,
        goods: {goods_discount},
        initPrice,
        initTaxPrice
      } = val
      //goods_discount = [{ min: 20, max: 30 }]
      console.log(val, goods_discount, Array.isArray(goods_discount), goods_discount.length);

      //return;
      if (initTaxPrice) {

        total_price = this.accMul(quantity, initTaxPrice)
        if (Array.isArray(goods_discount) && !goods_discount.some(j => j.ask_price_type == 0 && quantity >= j.min && j.max >= quantity)) {

          val.isNotfound = true
          val.price = '暂无价格请转报价单'
          updateShopping({id, goods_id, production_model, quantity})
        } else {
          val.isNotfound = false;
          val.price = initPrice;
          val.tax_price = initTaxPrice;
          //this.$forceUpdate();
          updateShopping({
            id,
            goods_id,
            production_model,
            quantity,
            price: initPrice,
            tax_price: initTaxPrice,
            total_price
          })
        }
        //updateShopping({ id, goods_id, production_model, quantity, price: initPrice, tax_price: initTaxPrice, total_price })
      } else {
        updateShopping({id, goods_id, production_model, quantity})
      }

    }
  },
  created() {
    this.getShopingList()
  }

}
</script>
<style lang="scss" scoped>
.pl-shopping-cart-wrap {
  .pl-table {
    width: 100%;
  }

  .pl-img-box {
    width: 20px;
    height: 20px;
    cursor: pointer;
    margin: auto;

    img {
      height: 100%;
    }
  }

  .pl-pagination {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .pl-footer-left {
    span {
      padding: 0 15px;
      border-right: 1px solid rgb(132, 131, 131);
      cursor: pointer;
    }

    span:last-child {
      border: 0
    }
  }

  ::v-deep .el-input-number--mini {
    width: 100%;
  }

}

::v-deep {
  .el-checkbox__input.is-checked .el-checkbox__inner {
    border: 1px solid #D9262C;
    background-color: #D9262C;
  }

  .el-checkbox__input.is-focus {
    border-color: #D9262c;
  }

  .el-checkbox__inner:hover {
    border-color: #D9262c;

  }

  .el-pagination.is-background .el-pager li:not(.disabled).active {
    background-color: #D9262c;

  }

  .el-button--primary.is-plain {
    background-color: #F5F6FB;
    color: #D9262c;
    // border-color: #D9262c;
  }

  .el-button--primary.is-plain:hover {
    border-color: #D9262C;

  }

  .el-button--primary {
    background-color: #D9262c;
    color: #fff;
    border-color: #D9262c;
  }
}
</style>
